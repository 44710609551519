import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import ActionType from '@/actions/action-type';
import MenuList from '@/components/MenuList/MenuList';
import { getAppTheme, getPageBeingAnimated, pageRecoverableData, setScreenColor, getIntl } from '@/components/App/App';
// import Layout from '@/components/Layout';
import { IStore, IStoreTable, IBlog, IStoreConfig, IStoreImage, IStorePickupLocation } from '@/actions/store-action';
import { pxToRem, checkImgExists, scalePx, findNode, getLocalStorageJson, setLocalStorageJson } from '@/utils';
import Config from '@/Config';
import Icon, { IconType } from '@/components/Icon';
import MenuNav from './MenuNav';
import { IFavoriteSection, IFavoriteTab, IListing } from '@/lib/order-builder/utils/api';
import paths from '@/routes/paths';
import ShoppingCartBar from '@/components/ShoppingCartBar/ShoppingCartBar';
import { parseOpeningHours, IParseOpeningHoursResult, getCurrentShippingMethod, getI18nText, getDifferentShippingMethodStore } from '@/utils/app';
import StoreInfo from './StoreInfo';
import { IObject } from '@/components/bm-common';
import { cancelFavorite } from '@/data/StoreSetting';
import Modal from 'antd-mobile/es/modal';
import ProcurementMethod from '@/components/ProcurementMethod/ProcurementMethod';
import { ISetShippingMethodParams, IOrderInfo } from '@/lib/order-builder/models/OrderEntity';
import {
  IFeedback,
  IFeedbackTags,
} from '@/data/Feedback';
import NewsList from '../../components/NewsList/NewsList';
import FeedbackList from '../../components/FeedbackList/FeedbackList';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import Skeleton from './Skeleton';
import MenuFunctions from './MenuFunctions';
import StoreFunction from '@/actions/store-function';
import HomeType from '@/actions/home-type';
import Constants from '@/constants';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { setUseLocalBrandID } from '../StoresByCategoryPage/StoresByCategoryPage';
import dayjs from 'dayjs';
import { formatCurrency } from '@/components/Payment';
import ModifierPage from '../ModifierPage/NewModifierPage';
import BmNavBar from '@/components/SideNavBar/BmNavBar';
import Layout from '@/components/Layout';
import InputItem from 'antd-mobile/es/input-item';
import { generateData } from '@/components/ProcurementMethod/Utils';
import { IPickerOptionData } from '@/components/ProcurementMethod';

export interface IMenuListPageProps extends IBasePageProps {
  menus?: IFavoriteTab[];
  store?: IStore;
  storeConfig?: IStoreConfig;
  isHomePage?: boolean;
  showProcurementMethod?: boolean;
}

export interface IMenuListPageState {
  page: number;
  store?: IStore;
  storeConfig?: IStoreConfig;
  menus: IFavoriteTab[];
  storeImgUrl: string;
  height: string;
  minHeight: string;
  maxHeight: string;
  menuNavFixed: boolean;
  titleFixed: boolean;
  selectedMenuID: string;
  shoppingCartData?: IShoppingCartData;
  storeSlug: string;
  isFavorite: boolean;
  favoriteID: string;
  showMenuNavLeftTag: boolean;
  showMenuNavRightTag: boolean;
  storeTables: IStoreTable[];
  list: IBlog[];
  loadingData: boolean;
  navIndex: number;
  selectedTabKey?: StoreFunction;
  showProcurementMethodPopup: boolean;
  feedbacks: IFeedback[] | undefined;
  feedbackTags: IFeedbackTags;
  total: number;
  loadingFeedback: boolean;
  storeImages: IStoreImage[];
  orderNumber: string;
  authCode: string;
  showListingDetail: boolean;
  currentListing: IListing | undefined;
  quantity: number;
  selected?: boolean;
  recordID?: string;
  showAddExpressOrder: boolean;
  customProductName: string;
  customProductPrice: number;
  isActive?: boolean;
  showListingDetailModifier: boolean;
  listingID: string;
}

let canAutoProcurementMethodPopup = false;

export const setCanAutoProcurementMethodPopup = (auto: boolean) => {
  canAutoProcurementMethodPopup = auto || false;
}

class MenuListPage extends BasePage<IMenuListPageProps, IMenuListPageState> {
  loadingData = false;
  lastPage = false;
  selectedMenuID = '';
  routeParams: any;
  disabled = false;
  autoShowProcurementMethod = false;

  constructor(props: IMenuListPageProps) {
    super(props);
    const { routeParams } = this.props;
    const mode = routeParams.mode === 'dedicated' ? 'D' : 'N';
    localStorage.setItem(Constants.DEDICATED_QUERY, mode);

    if (routeParams.showProcurementMethod || props.showProcurementMethod) {
      this.autoShowProcurementMethod = true;
    }

    this.routeParams = routeParams;
    const { store, menus, storeConfig } = props;
    let storeSlug = '';
    if (routeParams.storeSlug) {
      storeSlug = routeParams.storeSlug;
    } else if (store) {
      storeSlug = store.slug;
    }

    let selectedMenuID = '';
    if (menus && menus.length > 0) {
      selectedMenuID = menus[0].id;
    }

    this.selectedMenuID = selectedMenuID;

    this.state = {
      page: 1,
      store,
      storeConfig,
      menus: menus || [],
      storeImgUrl: Config.defaultStoreImg,
      height: pxToRem(332),
      minHeight: pxToRem(88),
      maxHeight: pxToRem(332),
      menuNavFixed: false,
      titleFixed: false,
      selectedMenuID,
      shoppingCartData: undefined,
      storeSlug,
      isFavorite: false,
      favoriteID: '',
      showMenuNavLeftTag: false,
      showMenuNavRightTag: false,
      storeTables: [],
      list: [],
      loadingData: true,
      navIndex: 0,
      selectedTabKey: this.getDefaultSelectedTabKey(storeConfig),
      showProcurementMethodPopup: false,
      feedbacks: undefined,
      feedbackTags: {},
      total: 0,
      loadingFeedback: false,
      storeImages: [],
      orderNumber: routeParams.orderNumber || '',
      authCode: routeParams.authCode || '',
      showListingDetail: false,
      currentListing: undefined,
      quantity: 1,
      selected: false,
      showAddExpressOrder: false,
      customProductName: '',
      customProductPrice: 0,
      isActive: true,
      showListingDetailModifier: false,
      listingID: '',
    }
  }

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.autoCloseLoading = false;
    const { isHomePage = false, routeParams, dispatch } = this.props;
    if (routeParams.routePath.includes('/sc/') && routeParams.shortcutCode) {
      dispatch({
        type: ActionType.QUERY_STORE_SLUG_BY_RANDOM_CODE,
        number: routeParams.shortcutCode,
        callback: (isActive, storeSlug) => {
          this.setState({
            storeSlug,
          })
          if (isActive) {
            this.getMenus(storeSlug);
          } else {
            this.setState({
              isActive: false,
              loadingData: false,
            })
          }
        }
      });
    }
    const { storeSlug, store, storeConfig, orderNumber, authCode } = this.state;
    if (orderNumber) {
      dispatch({
        type: ActionType.CANCEL_QRCODE_SHOPPING_INVOICE_TASK_SYNC,
      });

      if (authCode) {
        dispatch({
          type: ActionType.QUERY_QRCODE_SHOPPING_INVOICE,
          storeSlug,
          orderNumber,
          authCode,
        });
      }
    }

    dispatch({
      type: ActionType.QUERY_FAVORITE_LISTINGS,
    });

    if (!isHomePage && storeSlug) {
      if (routeParams.menuID) {
        const { reducersState } = this.props;
        const { deviceInfo } = reducersState.app;
        if ( deviceInfo.inPC ) {
          setTimeout(() => {
            this.setState({ menuNavFixed: true, titleFixed: true }, () => {
              this.getMenus(storeSlug);
            });
          }, 300);
        } else {
          setTimeout(() => {
            this.setState({ menuNavFixed: true, titleFixed: true }, () => {
              this.getMenus(storeSlug);
            });
          }, 600);
        }
      } else {
        this.getMenus(storeSlug);
      }
    } else if (!routeParams.routePath.includes('/sc/')) {
      this.checkShowProcurementMethod(storeConfig);
      this.setState({
        loadingData: false,
      });
      this.closeLoading();
    }

    if (isHomePage && store) {
      if (store.imgUrl) {
        checkImgExists(store.imgUrl, (success, url) => {
          if (success) {
            this.setState({ storeImgUrl: url });
          } else {
            this.setState({ storeImgUrl: Config.defaultStoreImg });
          }
        })
      }

      if (storeConfig) {
        if (storeConfig.storeSlug) {
          this.getStoreShoppingCartData(storeConfig, store);
        }

        if (storeConfig.showAboutUs) {
          this.getBlogList('COMPANY_INFO');
        }
      }

      this.getStoreImages(store.slug);
    }
    this.getFavoriteStatus();

    // if (menus && menus.length > 0) {
    //   const currentMenu = findNode<IFavoriteTab>(menus, 'id', this.routeParams.menuID || '');
    //   if (currentMenu) {
    //     this.handleMenuNavItemClick(currentMenu)
    //   } else {
    //     this.handleMenuNavScroll(300);
    //   }
    // }

    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.addEventListener('scroll', this.handleScroll, { passive: true });
    } else {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    this.handleGetStoreAssociate();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    if (pageWrap) {
      pageWrap.removeEventListener('scroll', this.handleScroll);
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  onLeftClick = () => {
    const { orderNumber, storeSlug } = this.state;
    pageRecoverableData[this.pathname] = {
      scrollY: 0,
    };
    if (orderNumber) {
      this.handleGoBack({
        path: paths.QRCODE_SHOPPING,
        runGoBack: true,
        pathParams: {
          storeSlug: storeSlug,
          orderNumber: orderNumber,
        },
      });
    } else {
      setUseLocalBrandID(true);
      
      this.handleGoBack({
        path: paths.HOME,
        // paths: [paths.HOME, paths.STORE_GROUP, paths.FAVORITE],
        runGoBack: true,
        pathParams: {
          storeSlug,
        },
      });
    }
  }

  getDefaultSelectedTabKey = (storeConfig?: IStoreConfig) => {
    let selectedTabKey: StoreFunction | undefined = undefined;

    if (storeConfig) {
      if (storeConfig.showOrder) {
        selectedTabKey = StoreFunction.ORDER;
      } else if (!storeConfig.showOrder && storeConfig.showMenu) {
        selectedTabKey = StoreFunction.MENU;
      } else if (!storeConfig.showOrder && storeConfig.showFeedback) {
        selectedTabKey = StoreFunction.FEEDBACK;
      } else if (!storeConfig.showOrder && storeConfig.showNews) {
        selectedTabKey = StoreFunction.NEWS;
      } else if (!storeConfig.showOrder && storeConfig.showAboutUs) {
        selectedTabKey = StoreFunction.ABOUT_US;
      }
    }

    return selectedTabKey;
  }

  getStoreImages = (storeSlug: string) => {
    const { dispatch } = this.props;
    dispatch({
      type: ActionType.QUERY_STORE_IMAGES,
      storeSlug,
      callback: (storeImages: IStoreImage[]) => {
        const { storeImgUrl } = this.state;
        let newStoreImgUrl = storeImgUrl;
        if (storeImages.length === 1 && (!newStoreImgUrl || newStoreImgUrl === Config.defaultStoreImg)) {
          newStoreImgUrl = storeImages[0].originalUrl || Config.defaultStoreImg;
        }

        if (storeImages.length > 0) {
          this.setState({
            storeImages,
            storeImgUrl: newStoreImgUrl,
          });
        }
      }
    });
  }

  checkShowProcurementMethod = (storeConfig?: IStoreConfig) => {
    let autoShow = true;
    if (storeConfig) {
      const autoPoppedData = getLocalStorageJson(Constants.AUTO_POPPED_PROCUREMENT_METHOD);
      if (autoPoppedData && autoPoppedData[storeConfig.storeSlug]) {
        autoShow = false;
      }
    }
    if (autoShow && canAutoProcurementMethodPopup && storeConfig && storeConfig.currentColor) {
      this.handleProcurementMethodPopupShow(true);
    }
  }

  getMenus = (storeSlug: string) => {
    const { dispatch, reducersState } = this.props;
    const { deviceInfo } = reducersState.app;
    dispatch({
      type: ActionType.QUERY_LISTINGS,
      storeSlug,
      callback: (menus, store, storeConfig) => {
        this.setState({
          store,
          storeConfig,
          selectedTabKey: this.getDefaultSelectedTabKey(storeConfig),
        });
        this.checkShowProcurementMethod(storeConfig);

        if (store) {
          this.getStoreImages(store.slug);
        }

        if (store && store.imgUrl) {
          checkImgExists(store.imgUrl, (success, url) => {
            if (success) {
              this.setState({ storeImgUrl: url });
            } else {
              this.setState({ storeImgUrl: Config.defaultStoreImg });
            }
          })
        }

        if (storeConfig && store) {
          if (storeConfig.storeSlug) {
            this.getStoreShoppingCartData(storeConfig, store);
          }

          if (storeConfig.showAboutUs) {
            this.getBlogList('COMPANY_INFO');
          }

          if ((!localStorage.getItem(Constants.ACCESS_TOKEN) || localStorage.getItem(Constants.IS_GUEST) === 'true') && !this.state.orderNumber && !deviceInfo.weChatMiniProgram && storeConfig.enableSignInReminder && sessionStorage.getItem(Constants.NITIALIZED_SIGN_IN_TIPS) !== 'YES') {
            Modal.alert(getIntl().page.notSignInTips, getIntl().page.signInPleaseTips, [
              {
                text: getIntl().page.noThanks,
                onPress: () => {
                  sessionStorage.setItem(Constants.NITIALIZED_SIGN_IN_TIPS, 'YES')
                },
              },
              {
                text: getIntl().page.signin,
                onPress: () => {
                  sessionStorage.setItem(Constants.NITIALIZED_SIGN_IN_TIPS, 'YES')
                  this.handleGotoRoute({
                    path: paths.SIGN_IN,
                  })
                },
              }
            ]);
          }
        }

        let selectedMenuID = '';
        const effectiveMenus = this.getEffectiveMenus(menus);
        if (effectiveMenus.length > 0) {
          selectedMenuID = effectiveMenus[0].id;
        }

        this.selectedMenuID = selectedMenuID;
        this.setState({
          menus,
          selectedMenuID,
          loadingData: false,
        }, () => {
          const currentMenu = findNode<IFavoriteTab>(menus, 'id', this.routeParams.menuID || '');
          if (currentMenu) {
            this.handleMenuNavItemClick(currentMenu)
          } else {
            this.handleMenuNavScroll(300);
          }
        });

        this.closeLoading();
      }
    });
  }

  getDateDefaultValue = () => {
    let defaultSelectedDate = '';
    let defaultSelectedTime = '';
    let defaultSelectedAmPm = '';
    const { shoppingCartData } = this.state;
    const { orderInfo } = shoppingCartData || {};
    const { deliveryDate } = orderInfo || {};
    if ([ShippingMethod.PICK_UP, ShippingMethod.DELIVERY].includes(ShippingMethod.PICK_UP) && deliveryDate && deliveryDate.length > 10) {
      const tempDate = dayjs(deliveryDate);
      defaultSelectedDate = tempDate.format('YYYY-MM-DD');
      defaultSelectedTime = tempDate.format('HH:mm:ss');
      if (defaultSelectedTime >= '12:00:00') {
        defaultSelectedAmPm = 'PM';
      } else {
        defaultSelectedAmPm = 'AM';
      }
    }

    return {
      defaultSelectedDate,
      defaultSelectedTime,
      defaultSelectedAmPm,
    };
  }

  setDefaultShippingTime = (currentShippingMethod: ShippingMethod, storeConfig: IStoreConfig, store: IStore) => {
    // const { dispatch } = this.props;
    // const { store, storeConfig } = this.state;
    if (store && storeConfig) {
      const {
        dateOptions,
        dayRefTimeOptions,
        currentDayTimeOptions,
      } = generateData({
        procurementMethod: currentShippingMethod,
        store,
        storeConfig,
      });
      const {
        defaultSelectedDate,
      } = this.getDateDefaultValue();
      const dateFormat = dayjs().format('YYYY-MM-DD');
      let dateNode = findNode<IPickerOptionData>(dateOptions, 'value', dateFormat);
      if (!dateNode && storeConfig.pickUpAdvanceOrderDate > 1) {
        dateNode = findNode<IPickerOptionData>(dateOptions, 'value', dayjs(dateFormat).add(1, 'day').format('YYYY-MM-DD'));
      }
      let timeOptions: IPickerOptionData[] = [];
      if (dateFormat === defaultSelectedDate && currentDayTimeOptions.length > 0) {
        timeOptions = currentDayTimeOptions;
      } else if (currentDayTimeOptions.length > 0) {
        timeOptions = currentDayTimeOptions;
      } else if (dateNode && Array.isArray(dayRefTimeOptions[dateNode.day])) {
        timeOptions = dayRefTimeOptions[dateNode.day];
      }
      if (dateOptions.length > 0) {
        const day = `${dateOptions[0].value} ${timeOptions[0].value}`;
        let date = dayjs(day);
        const nowDate = dayjs(new Date());
        if (dayjs(date).isBefore(nowDate)) {
          date = nowDate;
        }
  
        this.handleProcurementMethodChange({
          shippingMethod: currentShippingMethod,
          deliveryDate: date,
        })
      } else {
        this.handleProcurementMethodChange({
          shippingMethod: currentShippingMethod,
          deliveryDate: undefined,
        })
      }

    }
  }

  getStoreOpenStatus = (storeConfig: IStoreConfig | undefined, store: IStore | undefined, orderInfo: IOrderInfo | undefined) => {
    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    // const { store, storeConfig } = this.state;
    const { openingHours } = store || {};
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }
    const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);

    let isOpen = false;
    let currentDay = 1;
    if (parseOpeningHoursResult) {
      isOpen = parseOpeningHoursResult.isOpen;
      currentDay = parseOpeningHoursResult.currentDay;
      if (currentShippingMethod === ShippingMethod.PICK_UP && storeConfig && storeConfig.pickUpAdvanceOrderDate > 1) {
        isOpen = true;
      } else if (currentShippingMethod === ShippingMethod.DELIVERY && storeConfig && storeConfig.deliveryAdvanceOrderDate > 1) {
        isOpen = true;
      }
    }
    return {
      isOpen,
      currentDay,
    }
  }
  

  getStoreShoppingCartData = (storeConfig: IStoreConfig, store: IStore) => {
    const { dispatch, routeParams } = this.props;
    if (!routeParams.uuid) {
      dispatch({
        type: ActionType.GET_STORE_SHOPPING_CART_DATA,
        storeSlug: storeConfig.storeSlug,
        resetDineInTable: routeParams.uuid ? false : true,
        callback: params => {
          this.setState({ shoppingCartData: params.shoppingCartData });
          const { orderInfo } = params.shoppingCartData || {};
          const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);
          // const { store } = this.state;
          const { isOpen } = this.getStoreOpenStatus(storeConfig, store, orderInfo);
          console.info(isOpen);
          if (!isOpen && store) {
            dispatch({
              type: ActionType.CLEAR_STORES_SHOPPING_CART,
              storeIDs: [`${store.id}`],
            });
          }
          if (store) {
            if (currentShippingMethod === ShippingMethod.PICK_UP || currentShippingMethod === ShippingMethod.DELIVERY) {
              this.setDefaultShippingTime(currentShippingMethod, storeConfig, store);
            }
          }
        },
      });
    }

    if (storeConfig.showOrder) {
      if (routeParams.proc && routeParams.proc === 'pick_up') { // 打包外卖
        this.handleProcurementMethodChange({
          shippingMethod: ShippingMethod.PICK_UP,
        });
      } else if (routeParams.proc && routeParams.proc === 'delivery') { // 速递
        this.handleProcurementMethodChange({
          shippingMethod: ShippingMethod.DELIVERY,
        });
      } else if (routeParams.proc && routeParams.proc === 'dine_in' && !routeParams.uuid) { // 堂食自取，没有桌台号
        this.handleProcurementMethodChange({
          shippingMethod: ShippingMethod.DINE_IN_UNASSIGNED,
        });
      } else if (routeParams.routePath.includes('qrcode-shopping')) { // 扫码下单
        this.handleProcurementMethodChange({
          shippingMethod: ShippingMethod.QRCODE_SHOPPING,
        });
      } else {
        dispatch({
          type: ActionType.QUERY_STORE_TABLES,
          storeSlug: storeConfig.storeSlug,
          callback: storeTables => {
            this.setState({ storeTables });

            /** 根据地址信息切换就餐方式 */
            let storeTable: IStoreTable | undefined;
            if (routeParams.uuid) {
              storeTable = findNode<IStoreTable>(storeTables, 'uuid', routeParams.uuid);
            }

            if (storeTable) {
              this.handleProcurementMethodChange({
                shippingMethod: ShippingMethod.DINE_IN,
                dineInParty: {
                  numberOfPeople: storeTable.numberOfPeople,
                  status: '',
                  tableID: storeTable.id,
                  tableName: storeTable.name,
                  turnTime: storeTable.turnTime,
                },
              });

              dispatch({
                type: ActionType.GET_STORE_SHOPPING_CART_DATA,
                storeSlug: storeConfig.storeSlug,
                resetDineInTable: routeParams.uuid ? false : true,
                data: {
                  shippingMethod: ShippingMethod.DINE_IN,
                  dineInParty: {
                    numberOfPeople: storeTable.numberOfPeople,
                    status: '',
                    tableID: storeTable.id,
                    tableName: storeTable.name,
                    turnTime: storeTable.turnTime,
                  },
                },
                callback: params => {
                },
              });
            }
          },
        });
      }
    }
  }

  scrollTimer: any = null;

  scrollLeft = (dom: any, number = 0, time?: any) => {
    if (!time) {
      dom.scrollLeft = number;
      return;
    }
    const spacingTime = 20 // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime // 计算循环的次数
    let left = dom.scrollLeft // 获取当前滚动条位置
    const everLeft = (number - left) / spacingInex // 计算每次滑动的距离
    if (this.scrollTimer) {
      clearInterval(this.scrollTimer) // 清除计时器
    }
    this.scrollTimer = setInterval(() => {
      if (spacingInex > 0) {
        spacingInex--
        this.scrollLeft(dom, left += everLeft)
      } else {
        clearInterval(this.scrollTimer) // 清除计时器
      }
    }, spacingTime)
  }

  noNavScrolling = false;

  handleNavScroll = (checkedMenuID: string) => {
    if (checkedMenuID && checkedMenuID !== this.selectedMenuID) {
      this.selectedMenuID = checkedMenuID;
      this.setState({
        selectedMenuID: checkedMenuID,
      })

      const storeMenuNavItem = document.getElementById(`bm-store-menus-nav-item-${checkedMenuID}`);
      const storeMenuNavBox = document.getElementById('bm-store-menus-nav-box');
      const storeMenuNavContent = document.getElementById('bm-store-menus-nav-content');
      if (storeMenuNavItem && storeMenuNavBox && storeMenuNavContent) {
        const itemOffsetLeft = storeMenuNavItem.offsetLeft;
        const itemOffsetWidth = storeMenuNavItem.offsetWidth;
        const boxOffsetWidth = storeMenuNavBox.offsetWidth;
        const boxScrollLeft = storeMenuNavBox.scrollLeft;
        const startValue = itemOffsetLeft - boxOffsetWidth - boxScrollLeft;
        const endValue = itemOffsetLeft + itemOffsetWidth - boxOffsetWidth - boxScrollLeft;

        if (startValue > 0 || endValue > 0 || itemOffsetLeft < boxScrollLeft) {
          // storeMenuNavBox.scrollLeft = itemOffsetLeft + (itemOffsetWidth / 2) - (boxOffsetWidth / 2)
          this.scrollLeft(storeMenuNavContent, itemOffsetLeft + (itemOffsetWidth / 2) - (boxOffsetWidth / 2), 300);
        }
      }
    }
  }

  getScrollY = () => {
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    let scrollY = window.scrollY;
    if (pageWrap) {
      scrollY = pageWrap.scrollTop;
    }

    return scrollY;
  }

  handleScroll = () => {
    if (getPageBeingAnimated()) {
      return;
    }
    const { titleFixed, menuNavFixed, selectedTabKey, page } = this.state;
    const appPages = document.getElementsByClassName('bm-p-menu-list');
    if (appPages && appPages.length) {
      const scrollY = this.getScrollY();
      const appPage = appPages[0];
      const scrollHeight = appPage.scrollHeight;
      const canScrollHeight = scrollHeight - window.innerHeight;
      this.getScrollY();
      if (selectedTabKey === StoreFunction.FEEDBACK && !this.lastPage && canScrollHeight - scrollY < 6 * 200) {
        this.getFeedback(page + 1, true);
      }
      const storeTitle = document.getElementById('bm-store-title');
      if (storeTitle) {
        if (storeTitle.offsetTop < scrollY) {
          if (!titleFixed) {
            this.setState({ titleFixed: true });
          }
        } else if (titleFixed) {
          this.setState({ titleFixed: false });
        }
      }

      const storeMenuNav = document.getElementById('bm-p-menu-list-functions');
      if (storeMenuNav) {
        if (storeMenuNav.offsetTop < scrollY) {
          if (!menuNavFixed) {
            this.setState({ menuNavFixed: true });
          }
        } else if (menuNavFixed) {
          this.setState({ menuNavFixed: false });
        }
      }

      if (this.noNavScrolling) return;

      const pageWrap = window.document.querySelector('.page-content-wrapper-web');
      let basePx = null;
      if (pageWrap) {
        basePx = scalePx(280);
      } else {
        basePx = scalePx(560);
      }
      let beforeMenuHide = false;
      let beforeMenuID = '';
      let checkedMenuID = '';
      const newMenus = this.getEffectiveMenus();
      for (let i = 0; i < newMenus.length; i++) {
        const menu = newMenus[i];
        if (i === 0) {
          checkedMenuID = menu.id;
        }
        const menuNavItem = document.getElementById(`bm-menu-panel-${menu.id}`);
        if (menuNavItem) {
          if (beforeMenuID && menuNavItem.offsetTop >= scrollY + basePx && beforeMenuHide) {
            checkedMenuID = beforeMenuID;
            break;
          }

          beforeMenuHide = menuNavItem.offsetTop < scrollY + basePx;
          beforeMenuID = menu.id;

          if (i === newMenus.length - 1 && beforeMenuHide) {
            checkedMenuID = beforeMenuID;
          }
        }
      }

      this.handleNavScroll(checkedMenuID);
    }
  }

  handleMenuNavItemClick = (data: IFavoriteTab) => {
    this.handleNavScroll(data.id);
    this.setState({
      selectedMenuID: data.id,
    })
    this.selectedMenuID = data.id;

    const storeMenuNav = document.getElementById(`bm-menu-panel-${data.id}`);
    const storeFunctions = document.getElementById('bm-p-menu-list-functions');
    const pageWrap = window.document.querySelector('.page-content-wrapper-web');
    const banner = window.document.querySelector('.color-banner');
    const storeMenuBox = document.getElementById('bm-store-menus-nav');
    const navbar = window.document.querySelector('.nav-blur');
    const { reducersState } = this.props;
    const { lessTheme } = reducersState.app;
    let storeMenuBoxHeight = null;
    let navbarHeight = null;
    if ( storeMenuBox && navbar) {
      storeMenuBoxHeight = storeMenuBox.offsetHeight;
      navbarHeight = navbar.clientHeight;
    }
    if (storeMenuNav && storeMenuBoxHeight && navbarHeight && storeFunctions && banner) {
      if (pageWrap) {
        pageWrap.scrollTo(0, storeMenuNav.offsetTop+storeFunctions.offsetTop+banner.clientHeight+8-(navbarHeight+storeMenuBoxHeight));
      } else {
        let top;
        if (lessTheme && lessTheme['@bm-safe-area-top']) {
          top = Math.round(Number(lessTheme['@bm-safe-area-top'].substr(0, lessTheme['@bm-safe-area-top'].indexOf('rem'))) * 75);
        }
        if (top && top > 0) {
          window.scrollTo(0, storeMenuNav.offsetTop+storeFunctions.offsetTop+banner.clientHeight+8-(navbarHeight+storeMenuBoxHeight+top));
        }else {
          window.scrollTo(0, storeMenuNav.offsetTop+storeFunctions.offsetTop+banner.clientHeight+8-(navbarHeight+storeMenuBoxHeight));
        }
      }
    }

    this.noNavScrolling = true;
    setTimeout(() => {
      this.noNavScrolling = false;
    }, 400)
  }

  handlePlusClick = (data: IListing | undefined, quantity = 1, action?: string) => {
    if (!data) return;
    const { dispatch, reducersState } = this.props;
    const { shoppingCartData, storeSlug, storeConfig, orderNumber } = this.state;
    const { listingCountRef = {}, orderInfo } = shoppingCartData || {};
    const { favoriteListings, refFavoriteIDs } = reducersState.listing.favoriteListingInfo || {};
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;

    if (data.modifiers.length > 0) {
      pageRecoverableData[this.pathname] = {
        scrollY: this.getScrollY(),
      };
      this.setState({showListingDetailModifier: true, listingID: data.id, storeSlug: data.storeSlug});
    } else {
      // 如果存在，增加数量
      if (action === 'RIGHT_PICTURE') {
        let selected = false;
        let recordID = '';
        if (favoriteListings.length > 0) {
          const key = `${data.storeID}.${data.favoriteTabID}.${data.favoriteSectionID}`;
          if (refFavoriteIDs[key] && refFavoriteIDs[key].includes(data.favoriteID.toString())) {
            selected = true;
            favoriteListings.forEach(item => {
              if (item.favoriteID.toString() === data.favoriteID.toString()) {
                recordID = item.id;
              }
            })
          }
        }
        this.setState({
          showListingDetail: true,
          currentListing: data,
          quantity: 1,
          selected,
          recordID,
        });
      } else if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
        dispatch({
          type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
          storeSlug,
          uuid: listingCountRef[data.id].uuids[0],
          disableServiceCharge,
          operate: 'ADD',
          quantity,
          callback: shoppingCartData => {
            this.setState({ shoppingCartData, showListingDetail: false });
          },
        });
      } else { // 如果不存在，把商品添加到购物车
        const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);
        const { slugRefStorePickupLocations } = reducersState.store;
        const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
        let date = dayjs();
        if (storeConfig) {
          date = date.add(storeConfig.delayedDeliveryTime, 'day');
        }
        
        let pickupLocation: IStorePickupLocation | undefined = undefined;
        let pickupLocationID: string | undefined = undefined;
        if (shoppingCartData && shoppingCartData.orderData.pickup_location) {
          pickupLocation = shoppingCartData.orderData.pickup_location;
          pickupLocationID = shoppingCartData.orderData.pickup_location_id;
        } else if (pickupLocations && pickupLocations.length > 0) {
          pickupLocation = pickupLocations[0];
          pickupLocationID = pickupLocations[0].id;
        }
        dispatch({
          type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
          storeSlug,
          listingID: data.id,
          shippingMethod: orderNumber ? ShippingMethod.QRCODE_SHOPPING: currentShippingMethod,
          disableServiceCharge,
          quantity,
          customItemInfo: {
            name: this.state.customProductName ? `${getIntl().page.custom}${this.state.customProductName}` : '',
            price: this.state.customProductPrice,
          },
          pickupLocation,
          pickupLocationID,
          deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : (currentShippingMethod === ShippingMethod.PICK_UP || currentShippingMethod === ShippingMethod.DELIVERY ? date : undefined),
          callback: (shoppingCartData, storeIDs) => {
            if (storeIDs && storeIDs.length > 0) {
              Modal.alert('', getIntl().page.differentShippingMethodTips, [
                {
                  text: getIntl().common.cancel,
                  onPress: () => { },
                },
                {
                  text: getIntl().common.continue,
                  onPress: () => {
                    dispatch({
                      type: ActionType.CLEAR_STORES_SHOPPING_CART,
                      storeIDs: storeIDs,
                    });
                    dispatch({
                      type: ActionType.PUSH_LISTING_TO_SHOPPING_CART,
                      storeSlug,
                      listingID: data.id,
                      shippingMethod: orderNumber ? ShippingMethod.QRCODE_SHOPPING: currentShippingMethod,
                      disableServiceCharge,
                      quantity,
                      clearDifferentData: true,
                      pickupLocation: pickupLocation || undefined,
                      pickupLocationID: pickupLocationID || undefined,
                      deliveryDate: orderInfo && orderInfo.deliveryDate ? dayjs(orderInfo.deliveryDate) : (currentShippingMethod === ShippingMethod.PICK_UP || currentShippingMethod === ShippingMethod.DELIVERY ? date : undefined),
                      callback: shoppingCartData => {
                        this.setState({ shoppingCartData, showListingDetail: false });
                      }
                    })
                  },
                }
              ]);
            } else {
              this.setState({ shoppingCartData, showListingDetail: false });
            }
          },
        });
      }
    }
  }

  handleMinusClick = (data: IListing) => {
    if (!data) return;
    const { dispatch } = this.props;
    const { shoppingCartData, storeSlug, storeConfig } = this.state;
    const { listingCountRef = {} } = shoppingCartData || {};
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;

    if (data.modifiers.length > 0) {
      // TODO 弹出对话框，减少
      if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
        dispatch({
          type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
          storeSlug,
          disableServiceCharge,
          uuid: listingCountRef[data.id].uuids[0],
          operate: 'MINUS',
          callback: shoppingCartData => {
            this.setState({ shoppingCartData });
          },
        });
      }
    } else {
      if (listingCountRef[data.id] && listingCountRef[data.id].quantity > 0) {
        dispatch({
          type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
          storeSlug,
          disableServiceCharge,
          uuid: listingCountRef[data.id].uuids[0],
          operate: 'MINUS',
          callback: shoppingCartData => {
            this.setState({ shoppingCartData });
          },
        });
      }
    }
  }

  handleAddFavorite = async () => {
    const { dispatch, reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { userInfo } = reducersState.user;
    if (!userInfo) {
      Modal.alert(getIntl().common.tips, getIntl().page.signInTips, [
        {
          text: getIntl().common.confirm,
          onPress: () => { },
        }
      ]);
      return;
    }
    if (!this.disabled) {
      this.disabled = true;
      if (this.state.isFavorite) {
        const res = await cancelFavorite(this.state.favoriteID);
        if (res) {
          this.setState({
            isFavorite: false,
          })
          this.disabled = false;
        }
      } else {
        if (platformAppSettings && userInfo) {
          dispatch({
            type: ActionType.ADD_FAVORITE,
            storeSlug: this.state.storeSlug,
            platformID: platformAppSettings.id,
            userID: userInfo.id,
            callback: id => {
              if (id) {
                this.setState({
                  isFavorite: true,
                  favoriteID: id,
                });
              }
              this.disabled = false;
            }
          });
        }
      }
    }
  }

  getFavoriteStatus = async () => {
    const { reducersState, dispatch } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { userInfo } = reducersState.user;
    if (platformAppSettings && userInfo) {
      dispatch({
        type: ActionType.QUERY_FAVORITE_STATUS,
        storeSlug: this.state.storeSlug,
        platformID: platformAppSettings.id,
        userID: userInfo.id,
        callback: id => {
          if (id) {
            this.setState({
              isFavorite: true,
              favoriteID: id,
            });
          } else {
            this.setState({
              isFavorite: false,
            })
          }
        }
      });
    }
  }

  menuNavScrollTime: any;

  handleMenuNavScroll = (time?: number) => {
    if (this.menuNavScrollTime) {
      clearTimeout(this.menuNavScrollTime)
      this.menuNavScrollTime = undefined;
    }

    this.menuNavScrollTime = setTimeout(() => {
      this.menuNavScrollTime = undefined;
      const storeMenuNavBox = document.getElementById('bm-store-menus-nav-box');
      const storeMenuNavContent = document.getElementById('bm-store-menus-nav-content');

      if (storeMenuNavBox && storeMenuNavContent) {
        let showMenuNavLeftTag = false;
        if (storeMenuNavBox.scrollLeft > 50) {
          showMenuNavLeftTag = true;
        }

        let showMenuNavRightTag = false;
        if (storeMenuNavBox.scrollLeft + storeMenuNavBox.offsetWidth < storeMenuNavContent.offsetWidth - 50) {
          showMenuNavRightTag = true;
        }

        this.setState({
          showMenuNavLeftTag,
          showMenuNavRightTag,
        });
      }
    }, time || 100);
  }

  handleProcurementMethodChange = (data: ISetShippingMethodParams) => {
    const { dispatch, reducersState } = this.props;
    const { storeSlug, store, storeConfig } = this.state;
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;
    const { slugRefStorePickupLocations } = reducersState.store;
    const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
    if (storeSlug) {
      let autoPoppedData: any = getLocalStorageJson(Constants.AUTO_POPPED_PROCUREMENT_METHOD);
      if (!autoPoppedData) {
        autoPoppedData = {};
      }
      if (!autoPoppedData[storeSlug]) {
        autoPoppedData[storeSlug] = true;
        setLocalStorageJson(Constants.AUTO_POPPED_PROCUREMENT_METHOD, autoPoppedData);
      }
    }
    const { storeIDRefShoppingCartData } = reducersState.cart;
    let storeIDs: string[] = [];
    if (store) {
      storeIDs = getDifferentShippingMethodStore(store.id, storeIDRefShoppingCartData, data.shippingMethod);
    }
    if (storeIDs.length > 0) {
      Modal.alert('', getIntl().page.differentShippingMethodTips, [
        {
          text: getIntl().common.cancel,
          onPress: () => { },
        },
        {
          text: getIntl().common.continue,
          onPress: () => {
            dispatch({
              type: ActionType.CLEAR_STORES_SHOPPING_CART,
              storeIDs: storeIDs,
            });
            dispatch({
              type: ActionType.CHANGE_SHIPPING_METHOD,
              storeSlug,
              disableServiceCharge,
              data: data.shippingMethod === ShippingMethod.PICK_UP ? { ...data, pickupLocationID: (pickupLocations.length > 0 && pickupLocations[0].id) || undefined, pickupLocation: pickupLocations[0] || null } : data,
              callback: shoppingCartData => {
                this.setState({ shoppingCartData });
              }
            });
          },
        }
      ]);
    } else {
      dispatch({
        type: ActionType.CHANGE_SHIPPING_METHOD,
        storeSlug,
        disableServiceCharge,
        data: data.shippingMethod === ShippingMethod.PICK_UP ? { ...data, pickupLocationID: (pickupLocations.length > 0 && pickupLocations[0].id) || undefined, pickupLocation: pickupLocations[0] || null } : data,
        callback: shoppingCartData => {
          this.setState({ shoppingCartData });
        }
      });
    }
  }

  handleProcurementMethodPopupShow = (show: boolean) => {
    this.setState({ showProcurementMethodPopup: show })
  }

  getBlogList = (type: string) => {
    const { dispatch } = this.props;
    const { storeSlug } = this.state;
    dispatch({
      type: ActionType.QUERY_BLOG_LIST,
      contentType: type,
      storeSlug: storeSlug,
      callback: list => {
        this.setState({
          list,
        })
      }
    })
  }

  getFeedback = (page: number, isAdd = false) => {
    const { dispatch, reducersState } = this.props;
    const { store, feedbacks } = this.state;
    const { platformConfig } = reducersState.app;

    this.setState({ loadingFeedback: true });

    if (store && (isAdd || !feedbacks || feedbacks.length === 0)) {
      dispatch({
        type: ActionType.QUERY_FEEDBACK_LIST,
        storeID: store.id,
        platformID: platformConfig.platformID,
        page,
        callback: (feedbackTag, feedBack, total) => {
          let newFeedbacks = feedbacks;
          if (isAdd && feedbacks) {
            newFeedbacks = feedbacks.concat(feedBack);
          } else {
            newFeedbacks = feedBack
          }
          this.setState({
            feedbacks: newFeedbacks,
            feedbackTags: feedbackTag,
            total,
            page,
            loadingFeedback: false,
          })
          this.lastPage = feedBack.length < 20 ? true : false;
        }
      })
    }
  }

  handleGoDetail = (data: IBlog) => {
    const { storeSlug } = this.state;
    let contentType = 'news';
    if (this.state.selectedTabKey === 'NEWS') {
      contentType = 'news';
    }
    if (this.state.selectedTabKey === 'ABOUT_US') {
      contentType = 'about-us';
    }

    this.handleGotoRoute({
      path: paths.BLOG_DETAIL,
      pathParams: {
        storeSlug,
        blogID: data.id,
        contentType,
      },
    });
  }

  filterMenu = (menus: IFavoriteTab[], shippingMethod: ShippingMethod) => {
    const newMenus: IFavoriteTab[] = [];
    const { routeParams, reducersState } = this.props;
    const { submittedOrderInfo } = reducersState.qrcordShopping.orderInfos;
    const listingIDs: string[] = [];

    if (submittedOrderInfo) {
      submittedOrderInfo.orderEntities.forEach(item => {
        if (Array.isArray(item.lineItems)) {
          item.lineItems.forEach(lineItem => {
            if (lineItem.id) {
              listingIDs.push(lineItem.id);
            }
          })
        }
      })
    }

    menus.forEach(menu => {
      const favoriteSections: IFavoriteSection[] = [];
      const { hasAddition, listingID } = menu.specialFavorite;
      if (
        !routeParams.orderNumber ||
        (
          routeParams.orderNumber &&
          (!hasAddition || (hasAddition && listingIDs.includes(listingID)))
        )
      ) {
        menu.favoriteSections.forEach(section => {
          const listings: IListing[] = [];
          section.listings.forEach(item => {
            if (Array.isArray(item.procurementMethod) && item.procurementMethod.includes(shippingMethod)) {
              listings.push(item);
            }
          });

          if (listings.length > 0) {
            favoriteSections.push({
              ...section,
              listings,
            })
          }
        });

        if (favoriteSections.length > 0) {
          newMenus.push({
            ...menu,
            favoriteSections,
          });
        }
      }
    });
    return newMenus;
  }

  handleChangeQuantity = (action: 'PLUS' | 'MINUS') => {
    const { quantity } = this.state;
    if (action === 'PLUS') {
      this.setState({
        quantity: quantity + 1,
      })
    } else {
      if (quantity === 1) return;
      this.setState({
        quantity: quantity - 1,
      })
    }

  }

  getEffectiveMenus = (currentMenus?: IFavoriteTab[]) => {
    const { shoppingCartData, storeConfig, menus, orderNumber } = this.state;
    const { orderInfo } = shoppingCartData || {};
    if (!currentMenus) {
      currentMenus = menus;
    }
    const currentShippingMethod = orderNumber ? ShippingMethod.DINE_IN : getCurrentShippingMethod(orderInfo, storeConfig);
    const newMenus = this.filterMenu(currentMenus, currentShippingMethod);

    return newMenus;
  }

  addExpressOrder = (data: IListing | undefined, quantity = 1) => {
    const { reducersState, dispatch } = this.props;
    const { suppliers } = reducersState.app.platformAppSettings || {};
    const { storeSlug } = this.state;
    this.setState({
      selected: !this.state.selected,
    }, () => {
      if (this.state.selected && suppliers && data) {
        dispatch({
          type: ActionType.ADD_FAVORITE_LISTING,
          storeSlug,
          favoriteID: data.favoriteID,
          favoriteTabID: data.favoriteTabID,
          favoriteSectionID: data.favoriteSectionID,
          callback: (success, recordID) => {
            this.setState({
              recordID,
            })
          }
        })
      }
      if (!this.state.selected && suppliers && data && this.state.recordID) {
        dispatch({
          type: ActionType.REMOVE_FAVORITE_LISTING,
          id: this.state.recordID,
          callback: success => {
            console.info(success)
          }
        })
      }
    });
  }

  handleGetStoreAssociate = () => {
    const { dispatch, reducersState } = this.props;
    const { selectStoreSlug } = reducersState.supplierInfo;
    const { suppliers } = reducersState.app.platformAppSettings || {};
    let showAddExpressOrder = false;

    if (suppliers) {
      dispatch({
        type: ActionType.QUERY_USER_ASSOCIATE_STORES,
        newRequest: true,
        callback: (stores: IStore[], associateTypesMap?: Map<string, IObject[]>) => {
          let store: IStore | undefined = undefined;
          stores.forEach(item => {
            if (item.slug === selectStoreSlug) {
              store = item;
            }
          });

          if (store && associateTypesMap && associateTypesMap.size > 0) {
            const associateTypes = associateTypesMap.get(selectStoreSlug);
            if (associateTypes && associateTypes.length > 0) {
              for (let i = 0; i < associateTypes.length; i++) {
                const tempAssociateType = associateTypes[i];
                if (tempAssociateType.functionSwitchForStore.addExpressOrder === true) {
                  showAddExpressOrder = true;
                  break;
                }
              }
            }
          }
          this.setState({ showAddExpressOrder });
          this.closeLoading();
        }
      });
    }
  }

  handleGoNextPage = (pathname: string) => {
    const { storeSlug } = this.state;
    this.handleGotoRoute({
      path: pathname,
      pathParams: {
        storeSlug,
      },
    });
  }
  handleModifierClose = () => {
    const { dispatch } = this.props;
    const { storeSlug } = this.state;
    
    this.setState({showListingDetailModifier: false});
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: storeSlug,
      callback: params => {
        this.setState({ shoppingCartData: params.shoppingCartData });
      },
    });
  }

  render() {
    const {
      reducersState,
      isHomePage = false,
      routeParams,
      history,
    } = this.props;
    const {
      store,
      storeConfig,
      storeImgUrl,
      menuNavFixed,
      titleFixed,
      selectedMenuID,
      shoppingCartData,
      showMenuNavLeftTag,
      showMenuNavRightTag,
      storeTables,
      list,
      loadingData,
      selectedTabKey,
      feedbacks,
      feedbackTags,
      total,
      loadingFeedback,
      storeSlug,
      storeImages,
      orderNumber,
      isActive,
      currentListing,
      showProcurementMethodPopup,
      authCode,
      showListingDetailModifier,
      listingID,
      showAddExpressOrder,
    } = this.state;
    const { openingHours, storeScore = 0 } = store || {};
    const { listingCountRef = {}, orderInfo } = shoppingCartData || {}
    const { platformConfig, homeType, deviceInfo } = reducersState.app;
    const { suppliers } = reducersState.app.platformAppSettings || {};

    let parseOpeningHoursResult: IParseOpeningHoursResult | undefined = undefined;
    if (openingHours) {
      parseOpeningHoursResult = parseOpeningHours(openingHours);
    }
    const currentShippingMethod = getCurrentShippingMethod(orderInfo, storeConfig);
    const newMenus = this.getEffectiveMenus();

    const {isOpen, currentDay } = this.getStoreOpenStatus(storeConfig, store, orderInfo);

    const layoutProps: IObject = {
      className: prefix,
    };

    if (isHomePage) {
      if (store && menuNavFixed) {
        // layoutProps.navContent = store.name;
      }
    } else {
      if (routeParams.mode !== 'dedicated' && isActive) {
        layoutProps.navIcon = <Icon className="circle-back-icon-outlined" type={IconType.LEFT_OUTLINED} />;
        layoutProps.onLeftClick = () => {
          pageRecoverableData[this.pathname] = {
            scrollY: 0,
          };
          if (orderNumber) {
            this.handleGoBack({
              path: paths.QRCODE_SHOPPING,
              runGoBack: true,
              pathParams: {
                storeSlug: storeSlug,
                orderNumber: orderNumber,
              },
            });
          } else {
            setUseLocalBrandID(true);
            this.handleGoBack({
              path: paths.HOME,
              // paths: [paths.HOME, paths.STORE_GROUP, paths.FAVORITE],
              runGoBack: true,
              pathParams: {
                storeSlug,
              },
            });
          }
        }
      }
      layoutProps.navRightContent = (
        !titleFixed && homeType !== HomeType.MENU_LIST && homeType !== HomeType.MENU_GRID && !orderNumber && isActive &&
        <div className="favorite-btn" onClick={this.handleAddFavorite}>
          {
            !this.state.isFavorite &&
            <Icon className="favorite-btn-icon" type={IconType.NOT_FAVORITE_OUTLINED} />
          }
          {
            this.state.isFavorite &&
            <Icon className="favorite-btn-icon favorited" type={IconType.ALREADY_FAVORITE_OUTLINED} />
          }
        </div>
      );
    }

    if (titleFixed && isActive) {
      layoutProps.className = `${prefix} title-fixed`;
      layoutProps.className = `${prefix} menu-nav-fixed`;
    }

    if (menuNavFixed) {
      if (!orderNumber) {
        layoutProps.navRightContent = (
          <ProcurementMethod
            id="bm-menus-dining-style-for-bar"
            className="dining-style"
            orderInfo={orderInfo}
            store={store}
            storeConfig={storeConfig}
            storeTables={storeTables}
            parseOpeningHoursResult={parseOpeningHoursResult}
            icon={IconType.DOWN_OUTLINED}
            currentShippingMethod={currentShippingMethod}
            history={history}
            onChange={this.handleProcurementMethodChange}
            onPopupShow={this.handleProcurementMethodPopupShow}
          />
        );
      }
    }
    if (!isActive) {
      layoutProps.navContent = getIntl().page.qrCodeTips;
      layoutProps.className = `${prefix} qr-code-tips-title`;
    }

    return (
      <Layout
        {...this.props}
        className="bm-p-menu-list menu-list-wrap"
      >
        <div>
          <div className="nav_bg nav-blur w-nav">
            {/* <div className="container-stretched-copy w-container">
              <div className="div-block-7 left-btn">
                <div className="nav-button-main">
                  <div className="html-embed-7 w-embed">
                    <Icon className="circle-back-icon-outlined" type={IconType.LEFT_OUTLINED} onClick={this.onLeftClick} />
                  </div>
                </div>
              </div>
            </div> */}
            {
              routeParams.mode !== 'dedicated' &&
                <BmNavBar
                  onLeftClick={this.onLeftClick}
                  navIcon={<Icon className="html-embed-7 w-embed left-circle" type={IconType.LEFT_OUTLINED}/>}
                  prefix="bm-p-menu-banner-bgcolor"
                  style={{position: 'fixed', backgroundColor:'transparent', width: '20%'}}
                ></BmNavBar>
            }
            {
              menuNavFixed &&
              <ProcurementMethod
                id="bm-menus-dining-style-for-bar"
                className={`dining-style dining-style-top ${menuNavFixed ? 'dining-style-fixed' : ''}`}
                orderInfo={orderInfo}
                store={store}
                storeConfig={storeConfig}
                storeTables={storeTables}
                parseOpeningHoursResult={parseOpeningHoursResult}
                icon={IconType.DOWN_OUTLINED}
                currentShippingMethod={currentShippingMethod}
                history={history}
                onChange={this.handleProcurementMethodChange}
                onPopupShow={this.handleProcurementMethodPopupShow}
              />
            }
            {
              newMenus.length > 0 && menuNavFixed && selectedTabKey && [StoreFunction.ORDER, StoreFunction.MENU].includes(selectedTabKey) &&
              <MenuNav
                data={newMenus}
                isFixed={menuNavFixed}
                selectedKey={selectedMenuID}
                showMenuNavLeftTag={showMenuNavLeftTag}
                showMenuNavRightTag={showMenuNavRightTag}
                onItemClick={this.handleMenuNavItemClick}
                onScroll={() => this.handleMenuNavScroll()}
                reducersState={reducersState}
              />
            }
          </div>
          {
            !loadingData && isActive &&
            <>
              {
                store &&
                <StoreInfo
                  storeImgUrl={storeImgUrl}
                  // titleFixed={titleFixed}
                  titleFixed={false}
                  parseOpeningHoursResult={parseOpeningHoursResult}
                  storeConfig={storeConfig}
                  store={store}
                  storeImages={storeImages}
                  reducersState={reducersState}
                  hideBooking={!!routeParams.orderNumber}
                />
              }
              <div className="div-block-68"  id="bm-p-menu-list-functions">
                <div className="color-banner"></div>
                <div className="tabs w-tabs">
                  {
                    storeConfig &&
                    <MenuFunctions
                      storeConfig={storeConfig}
                      selectedKey={selectedTabKey}
                      onItemClick={tabKey => {
                        this.setState({ selectedTabKey: tabKey });
                        if (tabKey === 'NEWS') {
                          this.getBlogList('BLOG');
                        }
                        if (tabKey === 'ABOUT_US') {
                          this.getBlogList('COMPANY_INFO');
                        }
                        if (tabKey === 'FEEDBACK') {
                          this.getFeedback(1);
                        }
                        if (tabKey === StoreFunction.BOOKING) {
                          this.handleGoNextPage(paths.BOOKING_NEW);
                        }
                        if (tabKey === StoreFunction.QUEUEING) {
                          this.handleGoNextPage(paths.QUEUEING_NEW);
                        }
                      }}
                    />
                  }
                  <div className="w-tab-content">
                    {
                      selectedTabKey && [StoreFunction.ORDER, StoreFunction.MENU].includes(selectedTabKey) &&
                      <div data-w-tab="Tab 1" className="tab-pane-tab-1 w-tab-pane w--tab-active">
                        {
                          !orderNumber &&
                          <ProcurementMethod
                            id="bm-menus-dining-style-for-bar"
                            className="dining-styleSubMenusSubMenus"
                            orderInfo={orderInfo}
                            store={store}
                            storeConfig={storeConfig}
                            storeTables={storeTables}
                            parseOpeningHoursResult={parseOpeningHoursResult}
                            icon={IconType.DOWN_OUTLINED}
                            currentShippingMethod={currentShippingMethod}
                            history={history}
                            onChange={this.handleProcurementMethodChange}
                            onPopupShow={this.handleProcurementMethodPopupShow}
                          />
                        }
                        {
                          newMenus.length > 0 && parseOpeningHoursResult &&
                          <MenuList
                            data={newMenus}
                            isOpen={isOpen}
                            currentDay={currentDay}
                            listingCountRef={listingCountRef}
                            onlyShow={storeConfig && !storeConfig.showOrder && storeConfig.showMenu}
                            onPlusClick={(data: IListing) => this.handlePlusClick(data, 1, platformConfig.menuDisplayMethod)}
                            onMinusClick={this.handleMinusClick}
                            reducersState={reducersState}
                            menuDisplayMethod={platformConfig.menuDisplayMethod}
                            currencyCode={store && store.currencyCode}
                            hideSubTitle={storeConfig && storeConfig.hideSecondaryMenu}
                          />
                        }
                      </div>
                    }
                    {
                      selectedTabKey === StoreFunction.FEEDBACK &&
                      <FeedbackList
                        storeScore={storeScore}
                        total={total}
                        feedbacks={feedbacks}
                        feedbackTags={feedbackTags}
                        loading={loadingFeedback}
                      />
                    }
                    {
                      selectedTabKey === StoreFunction.NEWS && list.length > 0 &&
                      <NewsList data={list} onItemClick={this.handleGoDetail} reducersState={reducersState} className="bm-p-menu-list"/>
                    }
                    {
                      selectedTabKey === StoreFunction.ABOUT_US &&
                      <div data-w-tab="Tab 4" className="w-tab-pane">
                        <div className="container w-container">
                          <div className="about-us-wrap">
                            <div className="store-address-wrap">
                              <div className="store-address">
                                <Icon className="address-icon" type={IconType.MAP_OUTLINED} />
                                <div className="msg-con">
                                  <div className="con">
                                    {store && (getI18nText(store, 'address1', reducersState.app.language) || store.address)}
                                  </div>
                                  {/* <Icon
                              type={IconType.RIGHT_OUTLINED}
                              className="right-icon"
                            /> */}
                                </div>
                              </div>
                              {
                                store &&
                                <div className="store-address">
                                  <Icon className="address-icon" type={IconType.TEL_PHONE_OUTLINED} />
                                  <div className="msg-con">
                                    {
                                      (platformConfig.hidePhoneNumberHyperlink || (deviceInfo.isNativeApp && !deviceInfo.isAppleApp)) &&
                                      <span className="con">{store.phone}</span>
                                    }
                                    {
                                      !platformConfig.hidePhoneNumberHyperlink && (!deviceInfo.isNativeApp || deviceInfo.isAppleApp) &&
                                      <a className="con" href={`tel: ${store.phone}`}>{store.phone}</a>
                                    }
                                  </div>
                                </div>
                              }
                              {
                                storeConfig && storeConfig.foodpanda &&
                                <div className="store-address">
                                  <Icon className="foodpanda-icon" type={IconType.FOODPANDA_OUTLINED} />
                                  <div className="msg-con">
                                    <a className="con" href={storeConfig.foodpanda}>{storeConfig.foodpanda}</a>
                                  </div>
                                </div>
                              }
                              {
                                storeConfig && storeConfig.deliveroo &&
                                <div className="store-address">
                                  <Icon className="deliveroo-icon" type={IconType.DELIVEROO_OUTLINED} />
                                  <div className="msg-con">
                                    <a className="con" href={storeConfig.deliveroo}>{storeConfig.deliveroo}</a>
                                  </div>
                                </div>
                              }
                              {
                                storeConfig && storeConfig.storeUrl &&
                                <div className="store-address">
                                  <Icon className="address-icon" type={IconType.STORE_URL_OUTLINED} />
                                  <div className="msg-con">
                                    <a className="con" href={storeConfig.storeUrl}>{storeConfig.storeUrl}</a>
                                  </div>
                                </div>
                              }
                              {
                                store && store.description &&
                                <>
                                  <div className="about-store">
                                    {getIntl().page.description}
                                  </div>
                                  <div className="store-description" dangerouslySetInnerHTML={{
                                    __html: (getI18nText(store, 'description', reducersState.app.language) || store.description)
                                  }} />
                                </>
                              }
                            </div>
                            {
                              list.length > 0 && <NewsList data={list} onItemClick={this.handleGoDetail} reducersState={reducersState} />
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          }
          {
            loadingData &&
            <Skeleton />
          }
          <div className={`modal-wrapper ${this.state.showListingDetail ? 'modal-wrapper-show' : ''}`}>
            <div className="modal-body">
              <div className="item-image-pop-up" style={{backgroundImage: `url(${(currentListing && currentListing.imageUrl) || '/images/default-store.png'})`}}>
                <div className="div-block-77">
                  <div className="nav-button-1 close-modal">
                    <Icon className="html-embed-7 w-embed circle" type={IconType.LEFT_OUTLINED} onClick={() => { this.setState({ showListingDetail: false })}} />
                  </div>
                </div>
              </div>
              <div className="food-description">
                <div className="div-block-69">
                  <div className="item-info">
                    <h4 className="heading-3">{currentListing?.aliasName || getI18nText(currentListing, 'name', reducersState.app.language) || currentListing?.name}</h4>
                    {
                      currentListing && currentListing.description &&
                      <div className="paragraph-2-pop-up" dangerouslySetInnerHTML={{ __html: getI18nText(currentListing, 'description', reducersState.app.language) || currentListing.description}}></div>
                    }
                  </div>
                  <div className="price">
                    {
                      currentListing &&
                      <div className="text-block-6">{formatCurrency(currentListing.price, store && store.currencyCode)}</div>
                    }
                  </div>
                </div>
                {
                  suppliers && showAddExpressOrder &&
                  <div className="add-express-order">
                    <div className="inventory-options">{getIntl().page.inventoryOptions}</div>
                    <div className="add-item-wrap"
                      onClick={() => { this.addExpressOrder(currentListing, this.state.quantity)}}>
                      <Icon
                        type={this.state.selected ? IconType.CHECKED_SQUARE_OUTLINED : IconType.UN_SELECTED_SQUARE_OUTLINED}
                        className="checked"
                      />
                      <div className="add-item">{getIntl().page.addToExpressOrder}</div>
                    </div>
                  </div>
                }
                {
                  currentListing && currentListing.overwriteLineItemNameEnabled && suppliers &&
                  <div className="item-information-box">
                    <div className="item-information">{getIntl().page.itemInformation}</div>
                    <div className="product-name-title">{getIntl().page.productName}</div>
                    <div className="product-name-wrap">
                      <div className="product-name">{getIntl().page.custom}</div>
                      <InputItem
                        autoComplete="off"
                        className="custom-name"
                        placeholder={getIntl().page.enter}
                        onChange={value => this.setState({customProductName: value})}
                        type="text"
                      />
                    </div>
                    <div className="product-price-wrap">
                      <div className="product-price">{getIntl().page.productPrice}</div>
                      <InputItem
                        autoComplete="off"
                        className="custom-price"
                        placeholder={getIntl().page.enter}
                        onChange={value => this.setState({customProductPrice: Number(value)})}
                      />
                    </div>
                  </div>
                }
                <div className="div-block-89">
                  <div className="q-flex">
                    <div className="q-dec">
                      <Icon
                        className={`item-minus ${this.state.quantity === 1 ? 'disable-minus' : ''}`}
                        type={IconType.MINUS_CIRCLE_OUTLINED}
                        onClick={() => this.handleChangeQuantity('MINUS')}
                      />
                    </div>
                    <div className="q-num">{this.state.quantity}</div>
                    <div className="q-inc">
                      <Icon
                        className="item-plus"
                        type={IconType.PLUS_CIRCLE_OUTLINED}
                        onClick={() => this.handleChangeQuantity('PLUS')}
                      />
                    </div>
                  </div>
                  <div data-w-id="42a1a8d4-0f2a-5b2e-88e6-c4ecee125597" className="add-to-cart-button close-modal" onClick={() => this.handlePlusClick(currentListing, this.state.quantity)}>
                    <div className="text-block-12">{getIntl().page.addShoppingCart}</div>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-id="42a1a8d4-0f2a-5b2e-88e6-c4ecee12559a" className="c-popup-close-time close-modal" onClick={() => { this.setState({ showListingDetail: false }); }}></div>
          </div>
          {
            showListingDetailModifier &&
            <div className="modal-wrapper modal-wrapper-show">
              <div className="modal-body modifier-page">
                <ModifierPage
                  {...this.props}
                  listingID={listingID.toString()}
                  storeSlug={storeSlug}
                  onClose={() => {this.handleModifierClose()}}
                ></ModifierPage>
              </div>
              <div className="c-popup-close-time close-modal" onClick={() => this.setState({showListingDetailModifier: false})}></div>
            </div>
          }
          
          {
            !loadingData && !showProcurementMethodPopup && store && storeConfig && storeConfig.showOrder && selectedTabKey !== StoreFunction.ABOUT_US &&
            <ShoppingCartBar
              store={store}
              reducersState={reducersState}
              className={isHomePage ? 'has-tab-bar' : ''}
              resetState={state => this.setState(state)}
              orderNumber={orderNumber}
              authCode={authCode}
              currencyCode={store.currencyCode}
            />
          }
        </div>
      </Layout>
    );
  }
}

export default MenuListPage;
